import { MenuItem } from '../../../models/dtos/ger/menuItem';
import { abrirTourReinf } from '../../../store/ducks/global.duck';

const LANCAMENTO = 'Lançamento';
const CFOP = 'CFOP';
const SIMPLES_NACIONAL = 'Simples Nacional';
const GIA_ICMS = 'GIA - ICMS';
const TERMO_DE_ABERTURA_E_ENCERRAMENTO = 'Termo de Abertura e Encerramento';
const CONHECIMENTO_TRASNPORTE = 'Conhecimento de Transporte';
const CONFIGURACOES = 'Configurações';
const PARAMETROS = 'Parâmetros';

const mlfMenu: MenuItem[] = [
  {
    icon: 'pe-7s-home',
    label: 'Home',
    to: '/mlf/dashboard',
  },
  {
    content: [
      {
        content: [
          {
            label: `${LANCAMENTO}`,
            to: '/mlf/lancamentoEntradaSearchView',
          },
          {
            label: 'Contas a Pagar',
            to: '/mlf/contaPagarSearchView',
          },
          {
            label: `${CONHECIMENTO_TRASNPORTE}`,
            to: '/mlf/conhecimentoTransporteEntradaSearchView',
          },
          {
            label: 'Importação',
            to: '/mlf/importacaoEntradaSearchView',
          },
          {
            label: 'Excluir por Período',
            to: '/mlf/excluirEntradaPeriodoProcessView',
          },
        ],
        label: 'Entrada',
      },
      {
        content: [
          {
            label: `${LANCAMENTO}`,
            to: '/mlf/lancamentoSaidaSearchView',
          },
          {
            label: 'Contas a Receber',
            to: '/mlf/contaReceberSearchView',
          },
          {
            label: 'Atividade Imobiliária',
            to: '/mlf/atividadeImobiliariaSearchView',
          },
          {
            label: `${CONHECIMENTO_TRASNPORTE}`,
            to: '/mlf/conhecimentoTransporteSaidaSearchView',
          },
          {
            label: 'Exportação',
            to: '/mlf/exportacaoSaidaSearchView',
          },
          {
            label: 'Redução Z',
            to: '/mlf/reducaoZSearchView',
          },
          {
            label: 'Documentos Fiscais Utilizados',
            to: '/mlf/documentosFiscaisUtilizadosSearchView',
          },
          {
            label: 'Operação com Cartão de Crédito/Débito',
            to: '/mlf/operacaoCartaoCreditoDebitoSearchView',
          },
          {
            label: 'Resumo Movimento Diário',
            to: '/mlf/movimentoDiarioSearchView',
          },
          {
            label: 'Excluir por Período',
            to: '/mlf/excluirSaidaPeriodoProcessView',
          },
        ],
        label: 'Saída',
      },
      {
        content: [
          {
            label: 'Produto',
            to: '/mlf/grupoProdutoSearchView',
          },
          {
            label: 'Tipo de Produto',
            to: '/mlf/grupoTipoProdutoSearchView',
          },
          {
            label: 'Movimentação Interna',
            to: '/mlf/grupoTipoMovimentacaoInternaSearchView',
          },
          {
            label: 'Serviço',
            to: '/mlf/grupoServicoSearchView',
          },
          {
            label: 'Participante - Fornecedor / Cliente / Transportadora / Operadoras de Cartão',
            to: '/mlf/grupoForcliSearchView',
          },
        ],
        label: 'Grupos',
      },
      {
        label: 'Rastrear por Nota',
        to: '/mlf/rastrearNotaEmpresaProdutoSearchView',
      },
      {
        content: [
          {
            content: [
              {
                label: 'COFINS',
                to: '/mlf/configCofinsSearchView',
              },
              {
                label: 'CSLL',
                to: '/mlf/configCsllSearchView',
              },
              {
                label: 'ICMS',
                to: '/mlf/configIcmsSearchView',
              },
              {
                label: 'ICMS S.T.',
                to: '/mlf/configIcmsSTSearchView',
              },
              {
                label: 'ICMS D.A.',
                to: '/mlf/configIcmsDaSearchView',
              },
              {
                label: 'ICMS Difal',
                to: '/mlf/configIcmsDifalSearchView',
              },
              {
                label: 'IPI',
                to: '/mlf/configIpiSearchView',
              },
              {
                label: 'IRPJ',
                to: '/mlf/configIrpjSearchView',
              },
              {
                label: 'IR R.F.',
                to: '/mlf/configIRRFSearchView',
              },
              {
                label: 'PIS',
                to: '/mlf/configPisSearchView',
              },
              {
                label: 'ISS',
                to: '/mlf/configIssSearchView',
              },
              {
                label: 'Retenção na Fonte',
                to: '/mlf/configRetencaoFonteSearchView',
              },
              {
                label: `${SIMPLES_NACIONAL}`,
                to: '/mlf/configSimplesNacionalSearchView',
              },
              {
                label: 'Situação Especial de ICMS',
                to: '/mlf/configSituacaoEspecialICMSSearchView',
              },
              {
                label: 'Situação Especial de IPI',
                to: '/mlf/configSituacaoEspecialIPISearchView',
              },
            ],
            label: `${PARAMETROS}`,
          },
          {
            content: [
              {
                label: 'COFINS',
                to: '/mlf/debitoCreditoCOFINSSearchView',
              },
              {
                label: 'CSLL',
                to: '/mlf/debitoCreditoCSLLSearchView',
              },
              {
                label: 'ICMS',
                to: '/mlf/debitoCreditoICMSSearchView',
              },
              {
                label: 'ICMS S.T.',
                to: '/mlf/debitoCreditoICMSSTSearchView',
              },
              {
                label: 'ICMS S.T. - Simples Nacional',
                to: '/mlf/debitoCreditoICMSSTSNSearchView',
              },
              {
                label: 'IPI',
                to: '/mlf/debitoCreditoIPISearchView',
              },
              {
                label: 'IRPJ',
                to: '/mlf/debitoCreditoIRPJSearchView',
              },
              {
                label: 'PIS',
                to: '/mlf/debitoCreditoPISSearchView',
              },
              {
                label: 'ISS',
                to: '/mlf/debitoCreditoISSSearchView',
              },
              {
                label: 'IR R.F.',
                to: '/mlf/debitoCreditoIRRFSearchView',
              },
              {
                label: 'Simples Nacional',
                to: '/mlf/debitoCreditoSimplesNacionalSearchView',
              },
              {
                label: 'Retenção na Fonte de PIS e COFINS',
                to: '/mlf/retencaoFontePisCofinsSearchView',
              },
            ],
            label: 'Lançamento de Débito e Crédito',
          },
          {
            label: 'Ressarcimento de ICMS S.T.',
            to: '/mlf/ressarcimentoICMSSTSearchView',
          },
          {
            label: 'Informações Complementares do ICMS',
            to: '/mlf/icmsInfCompSearchView',
          },
          {
            content: [
              {
                label: 'ICMS',
                to: '/mlf/obrigacoesICMSRecolherSearchView',
              },
              {
                label: 'ISS',
                to: '/mlf/obrigacaoIssRecolherSearchView',
              },
            ],
            label: 'Obrigações a recolher',
          },
          {
            label: 'GNRE',
            to: '/mlf/configGNRESearchView',
          },
          {
            content: [
              {
                label: `Entrada`,
                to: '/mlf/damefVafEntradaMLFSearchView',
              },
              {
                label: `Outra Entrada`,
                to: '/mlf/vafOutrasEntradasMLFSearchView',
              },
              {
                label: `Saída`,
                to: '/mlf/damefVafSaidaMLFSearchView',
              },
            ],
            label: `${LANCAMENTO} DAMEF/VAF`,
          },
          {
            label: `${LANCAMENTO} de Valores Contábeis`,
            to: '/mlf/valorContabilMLFSearchView',
          },
          {
            label: `${LANCAMENTO}s de Faturamento`,
            to: '/mlf/lancamentoFaturamentoSearchView',
          },
          {
            content: [
              {
                label: 'Ocorrência',
                to: '/mlf/ocorrenciaSearchView',
              },
              {
                label: 'Finalidade',
                to: '/mlf/finalidadeSearchView',
              },
              {
                label: 'Informações Complementares',
                to: '/mlf/giaSearchView',
              },
            ],
            label: 'GIA RJ',
          },
          {
            content: [
              {
                label: 'Localidade',
                to: '/mlf/giaSpLocalidadeSearchView',
              },
              {
                label: 'Ocorrência',
                to: '/mlf/giaSpSubItemSearchView',
              },
              {
                label: 'Informação Complementar',
                to: '/mlf/giaSpInfCompSearchView',
              },
              {
                label: 'DIPAM B',
                to: '/mlf/dipamGiaSpSearchView',
              },
            ],
            label: 'GIA SP',
          },
        ],
        label: 'Impostos',
      },
      {
        content: [
          {
            label: 'Tipo de Produto',
            to: '/mlf/tipoProdutoSearchView',
          },
          {
            label: 'Unidade de Medida',
            to: '/mlf/unidadeMedidaSearchView',
          },
          {
            label: 'Produto',
            to: '/mlf/produtoSearchView',
          },
          {
            label: 'Tipo Movimentação Interna',
            to: '/mlf/tipoMovimentacaoInternaSearchView',
          },
          {
            label: 'Movimentação Interna',
            to: '/mlf/movimentacaoInternaSearchView',
          },
          {
            label: 'Encerrantes de Bomba de Combustível',
            to: '/mlf/encerranteBombaCombustivelSearchView',
          },
          {
            label: 'Fechamento de Estoque de Combustível',
            to: '/mlf/fechamentoEstoqueCombustivelSearchView',
          },
          {
            label: 'Inventário',
            to: '/mlf/inventarioSearchView',
          },
          {
            label: 'Registro K200 - Estoque escriturado',
            to: '/mlf/estoqueEscrituradoSearchView',
          },
        ],
        label: 'Controle de Estoque',
      },
      {
        content: [
          {
            label: 'Serviço',
            to: '/mlf/servicoSearchView',
          },
          {
            label: 'Classificação de Serviço',
            to: '/mlf/classificacaoServicoSearchView',
          },
        ],
        label: 'Serviço',
      },
      {
        label: 'Recibo',
        to: '/mlf/reciboSearchView',
      },
      {
        label: 'Saldo Anterior do CIAP',
        to: '/mlf/creditoCiapSearchView',
      },
      {
        content: [
          {
            label: 'Lançamento Diário de Combustível',
            to: '/mlf/lancamentoDiarioCombustivelSearchView',
          },
          {
            label: 'Armazenamento de Combustível',
            to: '/mlf/armazenamentoCombustivelSearchView',
          },
          {
            label: 'Bomba de Combustível',
            to: '/mlf/bombaCombustivelSearchView',
          },
          {
            label: 'Bico de Bomba de Combustível',
            to: '/mlf/bicoBombaCombustivelSearchView',
          },
          {
            label: 'Registro de Contador do Bico de Combustível',
            to: '/mlf/contadorBicoBombaCombustivelSearchView',
          },
        ],
        label: 'Combustível',
      },
      {
        label: 'Participante - Fornec./Cli./Transp./Operadora de Cartão/Vale Transporte/Agência, Posto ou filial',
        to: '/mlf/forcliSearchView',
      },
      {
        label: 'Sociedade Uniprofissional - Empregados e Sócios',
        to: '/mlf/sociedadeUniProfissionalSearchView',
      },
      {
        label: 'Equipamento Fiscal',
        to: '/mlf/equipamentoFiscalSearchView',
      },
      {
        label: `Observação Padrão de ${LANCAMENTO}`,
        to: '/mlf/observacaoPadraoSearchView',
      },
      {
        label: 'Informação Complementar',
        to: '/mlf/informacaoComplementarSearchView',
      },
      {
        label: 'Ajuste e Informações de Documentos Fiscais',
        to: '/mlf/ajusteInfoFiscalSearchView',
      },
      {
        label: `${TERMO_DE_ABERTURA_E_ENCERRAMENTO}`,
        to: '/mlf/termoAberturaEncerramentoMLFSearchView',
      },
      {
        content: [
          {
            content: [
              {
                label: 'Origem da Mercadoria',
                to: '/mlf/origemMercadoriaSearchView',
              },
              {
                label: 'Situação Tributária',
                to: '/mlf/situacaoTributariaSearchView',
              },
              {
                label: 'IPI',
                to: '/mlf/cstIPISearchView',
              },
              {
                label: 'COFINS',
                to: '/mlf/cstCOFINSSearchView',
              },
              {
                label: 'PIS',
                to: '/mlf/cstPISSearchView',
              },
              {
                label: `${SIMPLES_NACIONAL}`,
                to: '/mlf/cstSimplesNacionalSearchView',
              },
            ],
            label: 'C.S.T',
          },
          {
            content: [
              {
                label: 'Gênero',
                to: '/mlf/ncmGeneroSearchView',
              },
              {
                label: 'NCM',
                to: '/mlf/ncmSearchView',
              },
            ],
            label: 'NCM',
          },
          {
            label: 'Alíquota padrão por Estado',
            to: '/mlf/aliquotaUFSearchView',
          },
          {
            label: 'CFOP',
            to: '/mlf/cfopSearchView',
          },
          {
            label: 'CNO',
            to: '/mlf/empresaCNOSearchView',
          },
          {
            label: 'Espécie',
            to: '/mlf/especieSearchView',
          },
          {
            label: 'Série',
            to: '/mlf/serieSearchView',
          },
          {
            label: 'Natureza da Receita',
            to: '/mlf/naturezaReceitaSearchView',
          },
          {
            label: 'Tipo de Crédito',
            to: '/mlf/tipoCreditoSearchView',
          },
          {
            label: 'Sociedade em Conta de Participação (SCP)',
            to: '/mlf/scpSearchView',
          },
          {
            label: 'Selic',
            to: '/mlf/selicSearchView',
          },
          {
            label: 'Multa',
            to: '/mlf/multaSearchView',
          },
          {
            label: 'Natureza de Crédito',
            to: '/mlf/naturezaCreditoSearchView',
          },
          {
            label: 'Atividade Econômica',
            to: '/mlf/atividadeEconomicaSearchView',
          },
          {
            label: 'Código DCTF para PIS/COFINS',
            to: '/mlf/codigoDctfSearchView',
          },
          {
            label: 'Receita Auferida por Instituições Financeiras',
            to: '/mlf/codigoReceitaFinanceiraSearchView',
          },
          {
            label: 'Detalhamento de Receita de Instituições Financeiras',
            to: '/mlf/codigoReceitaFinanceiraDetalhamentoSearchView',
          },
          {
            label: 'Indice de Participação do Município',
            to: '/mlf/indiceParticipacaoMunicipioSearchView',
          },
          {
            label: 'Natureza de Rendimento',
            to: '/mlf/naturezaRendimentoSearchView',
          },
        ],
        label: 'Tabelas',
      },
      {
        content: [
          {
            label: 'Empresa',
            to: '/mlf/configEmpresaMLFEditView',
          },
          {
            content: [
              {
                label: 'Codificação Fiscal',
                to: '/mlf/configIntegracaoContabilCFOPSearchView',
              },
              {
                label: 'Prestação de Serviços',
                to: '/mlf/configIntegracaoContabilServicosEditView',
              },
              {
                label: 'Fornecedor/Cliente/ Transportadora',
                to: '/mlf/configIntegracaoContabilForcliSearchView',
              },
              {
                label: 'Contas a Pagar e Receber',
                to: '/mlf/configIntegracaoContabilContaPagarReceberEditView',
              },
              {
                label: 'Imposto',
                to: '/mlf/configIntegracaoContabilImpostosEditView',
              },
              {
                label: 'Importar Integração',
                to: '/mlf/importarIntegracaoFiscalContabilProcessView',
              },
            ],
            label: 'Integração Contábil',
          },
          {
            content: [
              {
                label: 'Entrada e Saída',
                to: '/mlf/configLancamentoEntradaSaidaSearchView',
              },
              {
                label: `${CONHECIMENTO_TRASNPORTE}`,
                to: '/mlf/configLancamentoConhecimentoTransporteSearchView',
              },
              {
                label: 'Recibo',
                to: '/mlf/configReciboSearchView',
              },
              {
                label: 'Redução Z',
                to: '/mlf/configLancamentoReducaoZSearchView',
              },
            ],
            label: `${LANCAMENTO}`,
          },
          {
            label: 'Livro/Folha',
            to: '/mlf/configLivroFolhaMFLEditView',
          },
          {
            label: 'Limite de Imposto (ME/EPP/Presumido)',
            to: '/mlf/configLimiteMEEPPPEditView',
          },
          {
            label: 'Imposto por Empresa',
            to: '/mlf/configImpostoEmpresaSearchView',
          },
          {
            label: 'DIPJ',
            to: '/mlf/DIPJConfiguracaoMLFView',
          },
        ],
        label: `${CONFIGURACOES}`,
      },
    ],
    icon: 'pe-7s-edit',
    label: 'Manutenção',
  },
  {
    content: [
      {
        label: 'Calcular e consultar impostos',
        to: '/mlf/calcularImpostoProcessView',
      },
      {
        label: 'Gerar inventário',
        to: '/mlf/gerarInventarioProcessView',
      },
      {
        label: 'Baixar bem do ativo',
        to: '/mlf/baixaAtivoProcessView',
      },
      {
        label: 'Baixar Conta Pagar/Receber',
        to: '/mlf/baixarContaPagarReceberSearchView',
      },
      {
        label: 'Baixar Impostos',
        to: '/mlf/baixarImpostoProcessView',
      },
      {
        label: 'Refazer Lançamento Contábil',
        to: '/mlf/refazerLancamentosContabeisProcessView',
      },
      {
        label: 'Refazer Lançamento EFD-REINF',
        to: '/mlf/refazerLancamentosReinfProcessView',
      },
      {
        label: 'Excluir ICMS da base de PIS/COFINS',
        to: '/mlf/exclusaoIcmsPisCofinsProcessView',
      },
      {
        label: 'Preencher DEFIS',
        to: '/mlf/defisProcessView',
      },
      {
        label: 'Preencher PGDAS',
        to: '/mlf/pgDasProcessView',
      },
      {
        label: 'Fechamento/Restauração',
        to: '/mlf/fechamentoRestauracaoMLFProcessView',
      },
    ],
    icon: 'pe-7s-play',
    label: 'Processos',
  },
  {
    content: [
      {
        content: [
          {
            label: 'ADE 25/2010(IN86/2001) - PIS/COFINS',
            to: '/mlf/exportacaoADEProcessView',
          },
          {
            label: 'DeSTDA - Declaração de Substituição Tributária, Diferencial de Alíquota e Antecipação',
            to: '/mlf/exportacaoDeSTDAProcessView',
          },
          {
            label: 'DNF - Demonstrativo de Notas Fiscais',
            to: '/mlf/exportacaoDNFProcessView',
          },
          {
            label: 'DCTF - Declaração de Débitos e Créditos Tributários Federais',
            to: '/mlf/dctfExportacaoProcessView',
          },
          {
            label: 'DIPJ - Declaração de Rendimentos da Pessoa Jurídica',
            to: '/mlf/dipjExportacaoProcessView',
          },
          {
            label: 'SINTEGRA',
            to: '/mlf/exportacaoSintegraMLFProcessView',
          },
          {
            label: 'SPED EFD - Contribuições',
            to: '/mlf/spedPisCofinsExportacaoProcessView',
          },
          {
            label: 'SPED EFD - ICMS IPI',
            to: '/mlf/spedEFDIcmsIpiExportacaoProcessView',
          },
          {
            label: 'DMED',
            to: '/mlf/dmedExportacaoProcessView',
          },
          {
            content: [
              {
                action: () => abrirTourReinf('efd-reinf'),
                label: 'Envio',
              },
              {
                label: 'Monitor de eventos',
                to: '/mlf/monitorEventoReinfProcessView',
              },
              {
                label: 'Informações sobre planos de saúde',
                to: '/mlf/informacaoPlanoSaudeMLFSearchView',
              },
              {
                content: [
                  {
                    label: 'Empresa',
                    to: '/mlf/empresaReinfConfig',
                  },
                  {
                    label: 'CFOP',
                    to: '/mlf/reinfCFOPSearchView',
                  },
                ],
                label: `${CONFIGURACOES}`,
              },
            ],
            label: 'EFD-Reinf',
          },
          {
            content: [
              {
                label: 'DAC - ICMS',
                to: '/mlf/dacAlExportacaoProcessView',
              },
            ],
            label: 'AL - Alagoas',
          },
          {
            content: [
              {
                label: 'DMA - ICMS',
                to: '/mlf/dmaBaExportacaoProcessView',
              },
            ],
            label: 'BA - Bahia',
          },
          {
            content: [
              {
                label: 'DIEF - ICMS',
                to: '/mlf/diefCeEditView',
              },
            ],
            label: 'CE - Ceará',
          },
          {
            content: [
              {
                label: 'Livro Eletrônico GDF - BRASÍLIA',
                to: '/mlf/livroEletronicoFiscalGDFProcessView',
              },
            ],
            label: 'DF - Distrito Federal',
          },
          {
            content: [
              {
                label: 'DIEF - SINTEGRA',
                to: '/mlf/exportacaoSintegraMAMLFProcessView',
              },
              {
                label: 'DIEF - NFSD',
                to: '/mlf/diefMaExportacaoProcessView',
              },
            ],
            label: 'MA - Maranhão',
          },
          {
            content: [
              {
                label: 'DAPI - ICMS',
                to: '/mlf/exportacaoDAPIMLFProcessView',
              },
              {
                label: 'DES - PBH - ISS',
                to: '/mlf/desPBHEditView',
              },
            ],
            label: 'MG - Minas Gerais',
          },
          {
            content: [
              {
                label: `${GIA_ICMS}`,
                to: '/mlf/exportacaoGIAMTProcessView',
              },
            ],
            label: 'MT - Mato Grosso',
          },
          {
            content: [
              {
                label: 'DIEF - ICMS - Beta',
                to: '/mlf/diefPaExportacaoProcessView',
              },
            ],
            label: 'PA - Pará',
          },
          {
            content: [
              {
                label: 'GIM - ICMS',
                to: '/mlf/exportacaoGIMPBMLFProcessView',
              },
            ],
            label: 'PB - Paraíba',
          },
          {
            content: [
              {
                label: 'SEF 2012 (ICMS-GI, ICMS-LA, e-Doc Extrato)',
                to: '/mlf/sef2012PEExportacaoProcessView',
              },
            ],
            label: 'PE - Pernambuco',
          },
          {
            content: [
              {
                label: 'Adrcst - PR',
                to: '/mlf/adrcstPrExportacaoProcessView',
              },
            ],
            label: 'PR - Paraná',
          },
          {
            content: [
              {
                label: `${GIA_ICMS}`,
                to: '/mlf/exportacaoGIARJProcessView',
              },
              {
                label: `Declan-IPM`,
                to: '/mlf/declanExportacaoProcessView',
              },
            ],
            label: 'RJ - Rio de Janeiro',
          },
          {
            content: [
              {
                label: `${GIA_ICMS}`,
                to: '/mlf/exportacaoGiaRSProcessView',
              },
            ],
            label: 'RS - Rio Grande do Sul',
          },
          {
            content: [
              {
                label: 'DIME - ICMS',
                to: '/mlf/exportacaoDIMESCProcessView',
              },
            ],
            label: 'SC - Santa Catarina',
          },
          {
            content: [
              {
                label: 'CAT - 79/2003',
                to: '/mlf/exportacaoCat792003ProcessView',
              },
              {
                label: `${GIA_ICMS}`,
                to: '/mlf/exportacaoGIASPProcessView',
              },
              {
                label: 'REDF - Registro Eletrônico de Documentos Fiscais',
                to: '/mlf/exportacaoREDFProcessView',
              },
              {
                label: 'NFVC - Nota Fiscal de Venda de Consumidor Modelo 2',
                to: '/mlf/exportacaoNFVCProcessView',
              },
            ],
            label: 'SP - São Paulo',
          },
          {
            content: [
              {
                label: 'GIAM - ICMS',
                to: '/mlf/exportacaoGIAMTOProcessView',
              },
            ],
            label: 'TO - Tocantins',
          },
          {
            label: 'DIF - Declaração de Informações Fiscais',
            to: '/mlf/exportacaoDIFMLFProcessView',
          },
          {
            label: 'GissOnline',
            to: '/mlf/exportacaoGISSMLFProcessView',
          },
        ],
        label: 'Exportação',
      },
      {
        content: [
          {
            label: 'BP-e - Bilhete de Passagem Eletrônico',
            to: '/mlf/bPeImportacaoProcessView',
          },
          {
            label: 'CF-e / SAT - Cupom Fiscal Eletrônico',
            to: '/mlf/cfeSatImportacaoProcessView',
          },
          {
            label: `CT-e - ${CONHECIMENTO_TRASNPORTE} Eletrônico`,
            to: '/mlf/cTeImportacaoProcessView',
          },
          {
            label: 'Importar Convênio 115/2003',
            to: '/mlf/importacaoConv1152003ProcessView',
          },
          {
            label: 'NF-e - Nota Fiscal Eletrônica',
            to: '/mlf/nfeImportacaoXMLProcessView',
          },
          {
            isVisible: () => false,
            label: 'NF-e - Nota Fiscal Eletrônica - Via Certificado Digital',
            to: '/mlf/manifestacaoDestinatarioProcessView',
          },
          {
            label: 'NFC-e - Nota Fiscal de Consumidor Eletrônica',
            to: '/mlf/nfceImportacaoProcessView',
          },
          {
            label: 'NFP - Nota Fiscal Paulista / Cupom Fiscal ECF',
            to: '/mlf/importacaoNFPaulistaProcessView',
          },
          {
            label: 'NFS-e - Nota Fiscal de Serviço Eletrônica',
            to: '/mlf/importacaoNFSeMunicipioProcessView',
          },
          {
            label: 'Inventários - Planilha do Excel (Formato CSV, XLS e XLSX)',
            to: '/mlf/importacaoInventarioExcelProcessView',
          },
          {
            label: 'Atividades Imobiliárias - Planilha do Excel (Formato CSV, XLS e XLSX)',
            to: '/mlf/importacaoAtividadeImobiliariaExcelProcessView',
          },
          {
            label: 'Participante - Fornecedor / Cliente / Transportadora - Planilha do Excel (Formato CSV, XLS e XLSX)',
            to: '/mlf/importacaoForcliExcelProcessView',
          },
          {
            label: 'Produtos - Planilha do Excel (Formato CSV, XLS e XLSX)',
            to: '/mlf/importacaoProdutoCSVProcessView',
          },
          {
            label: 'Baixa de Contas a Pagar e Receber - Planilha do Excel (Formato CSV, XLS e XLSX)',
            to: '/mlf/baixaContasPagarReceberExcelProcessView',
          },
          {
            calimaPro: true,
            label: 'Recibos - Planilha do Excel (Formato CSV, XLS e XLSX)',
            to: '/mlf/importacaoReciboExcelProcessView',
          },
          {
            label: 'SINTEGRA',
            to: '/mlf/importacaoSintegraMLFMultiploProcessView',
          },
          {
            label: 'SPED EFD - ICMS IPI',
            to: '/mlf/importacaoSpedFiscalProcessView',
          },
          {
            label: 'Registro K200 - Planilha do Excel (Formato CSV, XML, XLSX)',
            to: '/mlf/importacaoK200ProcessView',
          },
        ],
        label: 'Importação',
      },
    ],
    icon: 'pe-7s-folder',
    label: 'Arquivos digitais',
  },
  {
    content: [
      {
        label: 'Recibo',
        to: '/mlf/ReciboReportView',
      },
      {
        content: [
          {
            label: 'Registro de Entrada',
            to: '/mlf/RegistroEntradaReportView',
          },
          {
            label: 'Aquisição Produtor Rural',
            to: '/mlf/aquisicaoProdutorRuralReportView',
          },
          {
            content: [
              {
                label: 'Produto',
                to: '/mlf/entradaProdutoReportView',
              },
              {
                label: `${CFOP}`,
                to: '/mlf/relacaoCodigoFiscalEntradaReportView',
              },
              {
                label: `${CFOP} e NCM`,
                to: '/mlf/relacaoCodigoFiscalNCMEntradaReportView',
              },
              {
                label: 'Espécie de Documento',
                to: '/mlf/relacaoEntradaEspecieDocumentoReportView',
              },
              {
                label: 'Fornecedor',
                to: '/mlf/relacaoEntradaFornecedorReportView',
              },
              {
                label: 'Alíquota',
                to: '/mlf/relacaoEntradaAliquotaReportView',
              },
              {
                label: 'Estado',
                to: '/mlf/relacaoEntradaEstadoReportView',
              },
              {
                label: 'Cidade',
                to: '/mlf/entradaCidadeReportView',
              },
              {
                label: 'Tipo de Pagamento',
                to: '/mlf/relacaoEntradaTipoPagamentoReportView',
              },
            ],
            label: 'Listagem',
          },
          {
            content: [
              {
                label: 'Listagem prestação de serviços',
                to: '/mlf/listagemPrestacaoServicoEntradaReportView',
              },
              {
                label: 'Registro de Prestação de Serviço',
                to: '/mlf/prestacaoServicoEntradaReportView',
              },
              {
                label: `${CFOP}`,
                to: '/mlf/relacaoCodigoFiscalServicoEntradaReportView',
              },
              {
                label: 'Tomados',
                to: '/mlf/servicoTomadoReportView',
              },
            ],
            label: 'Serviço',
          },
          {
            label: 'Conferência de Notas',
            to: '/mlf/conferenciaNotasEntradaReportView',
          },
          {
            label: 'Demonstrativo de Diferença de Alíquota',
            to: '/mlf/demonstrativoEntradaICMSDAReportView',
          },
          {
            label: 'Resumo de Operações Interestaduais',
            to: '/mlf/resumoOperacoesInterestaduaisEntradaReportView',
          },
          {
            label: 'Resumo dos Lançamentos',
            to: '/mlf/resumoLancamentoEntradaReportView',
          },
          {
            label: 'Alíquota Zero do PIS e COFINS',
            to: '/mlf/aliquotaZeroPISCOFINSEntradaReportView',
          },
          {
            label: 'Contas a Pagar',
            to: '/mlf/contaPagarReportView',
          },
          {
            label: 'Resumo Diário',
            to: '/mlf/resumoDiarioEntradaReportView',
          },
          {
            label: 'Retenção das Notas Fiscais',
            to: '/mlf/retencoesFornecedorReportView',
          },
        ],
        label: 'Entrada',
      },
      {
        content: [
          {
            label: 'Registro de Saída',
            to: '/mlf/RegistroSaidaReportView',
          },
          {
            content: [
              {
                label: `${CFOP}`,
                to: '/mlf/relacaoCodigoFiscalSaidaReportView',
              },
              {
                label: `${CFOP} e NCM`,
                to: '/mlf/relacaoCodigoFiscalNCMSaidaReportView',
              },
              {
                label: 'Espécie de Documento',
                to: '/mlf/relacaoSaidaEspecieDocumentoReportView',
              },
              {
                label: 'Série',
                to: '/mlf/registroSaidaSerieReportView',
              },
              {
                label: 'Cliente',
                to: '/mlf/relacaoSaidaClienteReportView',
              },
              {
                label: 'Alíquota',
                to: '/mlf/relacaoSaidaAliquotaReportView',
              },
              {
                label: 'Estado',
                to: '/mlf/relacaoSaidaEstadoReportView',
              },
              {
                label: 'Cidade',
                to: '/mlf/saidaCidadeReportView',
              },
              {
                label: 'Produto',
                to: '/mlf/saidaProdutoReportView',
              },
              {
                label: 'Operação Estadual',
                to: '/mlf/relacaoOperacoesEstaduaisSaidaReportView',
              },
              {
                label: 'Operação Interestadual',
                to: '/mlf/relacaoOperacoesInterestaduaisSaidaReportView',
              },
              {
                label: 'Demonstração Valor Combustível',
                to: '/mlf/demonstracaoValorCombustivelReportView',
              },
            ],
            label: 'Listagem',
          },
          {
            content: [
              {
                label: 'Registro de Prestação de Serviço',
                to: '/mlf/prestacaoServicoSaidaReportView',
              },
              {
                label: 'Listagem prestação de serviços',
                to: '/mlf/listagemPrestacaoServicoSaidaReportView',
              },
              {
                label: 'DMED',
                to: '/mlf/dmedReportView',
              },
              {
                label: 'Cliente',
                to: '/mlf/servicoClienteReportView',
              },
              {
                label: `${CFOP}`,
                to: '/mlf/relacaoCodigoFiscalServicoSaidaReportView',
              },
              {
                label: 'Faturamento Bruto por Tipo de Serviço',
                to: '/mlf/faturamentoBrutoServicoReportView',
              },
            ],
            label: 'Serviços',
          },
          {
            label: 'Conferência de Sequência de Notas Não Emitidas',
            to: '/mlf/sequenciaNotaReportView',
          },
          {
            label: 'Conferência de Notas',
            to: '/mlf/conferenciaNotasSaidaReportView',
          },
          {
            label: 'Resumo de Operações Interestaduais',
            to: '/mlf/resumoOperacoesInterestaduaisSaidaReportView',
          },
          {
            label: 'Resumo dos Lançamentos',
            to: '/mlf/resumoLancamentoSaidaReportView',
          },
          {
            label: 'Retenções das Notas Fiscais',
            to: '/mlf/retencoesNotasFiscaisReportView',
          },
          {
            label: 'Alíquota Zero do PIS e COFINS',
            to: '/mlf/aliquotaZeroPISCOFINSSaidaReportView',
          },
          {
            label: 'Contas a Receber',
            to: '/mlf/contaReceberReportView',
          },
          {
            label: 'Redução Z',
            to: '/mlf/reducaoZReportView',
          },
          {
            label: 'Resumo de Vendas',
            to: '/mlf/resumoVendasReportView',
          },
          {
            label: 'Resumo Diário',
            to: '/mlf/resumoDiarioSaidaReportView',
          },
          {
            label: 'Retenção por Cliente',
            to: '/mlf/retencoesClienteReportView',
          },
          {
            label: 'Tributação monofásica PIS/COFINS - Saída',
            to: '/mlf/tributacaoMonofasicaProcessView',
          },
        ],
        label: 'Saída',
      },
      {
        label: 'Recibos EFD-Reinf',
        to: '/mlf/reciboEFDReinfReportView',
      },
      {
        content: [
          {
            label: 'Listagem',
            to: '/mlf/forcliRelacaoCompleta',
          },
          {
            label: 'Lista de Emitentes - Modelo P10',
            to: '/mlf/listagemForcliEmitenteReportView',
          },
          {
            label: 'Lista de Clientes',
            to: '/mlf/listagemClienteReportView',
          },
        ],
        label: 'Fornec./Cli./Transp./Operadora de Cartão',
      },
      {
        content: [
          {
            label: 'Inventário auxiliar',
            to: '/mlf/inventarioReportView',
          },
          {
            label: 'Inventário - Modelo P7',
            to: '/mlf/inventarioP7ReportView',
          },
          {
            label: 'Tabela mercadoria - Modelo P11',
            to: '/mlf/tabelaMercadoriaP11ReportView',
          },
          {
            label: 'Controle de produção e estoque',
            to: '/mlf/controleProducaoEstoqueReportView',
          },
          {
            label: 'Relação de exportação de produtos completa',
            to: '/mlf/exportacoesExtrasReportView',
          },
          {
            label: 'Relação de produtos',
            to: '/mlf/relacaoProdutoReportView',
          },
        ],
        label: 'Controle de estoque',
      },
      {
        content: [
          {
            label: 'Memória de Cálculo',
            to: '/mlf/memoriaImpostoReportView',
          },
          {
            label: 'Demonstrativo Anual',
            to: '/mlf/demonstrativoImpostoReportView',
          },
          {
            label: 'Demonstrativo CST ICMS/IPI',
            to: '/mlf/demonstrativoCstIcmsIpiReportView',
          },
          {
            label: 'Demonstrativo CST PIS/COFINS',
            to: '/mlf/demonstrativoCSTPISCOFINSReportView',
          },
          {
            label: 'Pagos e a Pagar',
            to: '/mlf/impostoPagoPagarReportView',
          },
          {
            label: 'Resumo',
            to: '/mlf/resumoImpostoReportView',
          },
          {
            label: 'Guia DARF',
            to: '/mlf/guiaDarfMLFReportView',
          },
          {
            label: 'Guia DARF - Quotas',
            to: '/mlf/darfQuotaReportView',
          },
          {
            label: 'Guia de arrecadação estadual',
            to: '/mlf/guiaDaeReportView',
          },
          {
            label: 'Guia Avulsa',
            to: '/mlf/guiaAvulsaSearchView',
          },
          {
            label: 'Guia DAR-ISS',
            to: '/mlf/guiaDarIssReportView',
          },
          {
            label: 'GNRE',
            to: '/mlf/gnreReportView',
          },
          {
            label: 'Relação de Tributos ST - Nota Entrada',
            to: '/mlf/tributosReportView',
          },
          {
            label: 'RMD - Resumo Movimento Diário',
            to: '/mlf/movimentacoesReportView',
          },
        ],
        label: 'Impostos',
      },
      {
        content: [
          {
            label: 'Apuração de ICMS',
            to: '/mlf/apuracaoICMSReportView',
          },
          {
            label: 'Apuração de IPI',
            to: '/mlf/apuracaoIPIReportView',
          },
          {
            content: [
              {
                label: 'Completo',
                to: '/mlf/damefVafCompletaReportView',
              },
              {
                label: 'Simplificado',
                to: '/mlf/damefVafSimplesReportView',
              },
            ],
            label: 'DAMEF/VAF',
          },
          {
            label: 'DAPI - MG',
            to: '/mlf/dapiReportView',
          },
          {
            label: 'CIAP - Modelo C',
            to: '/mlf/ciapReportView',
          },
          {
            label: `${SIMPLES_NACIONAL}`,
            to: '/mlf/simplesNacionalReportView',
          },
          {
            label: 'DIPJ',
            to: '/mlf/dipjReportView',
          },
          {
            content: [
              {
                label: 'Entrada ICMS-ST substituto',
                to: '/mlf/EntradaICMSSTSubstitutoReportView',
              },
              {
                label: 'Saída ICMS efetivo',
                to: '/mlf/SaidaICMSEfetivoReportView',
              },
              {
                label: 'Registro de apuração do ICMS-ST Simples Nacional (RASTSN)',
                to: '/mlf/registroApuracaoICMSSTSimplesNacionalReportView',
              },
            ],
            label: 'Resolução SEFAZ-RJ 578/2023 (RASTSN)',
          },
        ],
        label: 'Declarações',
      },
      {
        content: [
          {
            label: 'Modelo 1',
            to: '/mlf/faturamentoBrutoModelo1ReportView',
          },
          {
            label: 'Modelo 2',
            to: '/mlf/faturamentoBrutoModelo2ReportView',
          },
          {
            label: 'Modelo 3',
            to: '/mlf/faturamentoBrutoModelo3ReportView',
          },
          {
            label: 'Modelo 4',
            to: '/mlf/faturamentoBrutoModelo4ReportView',
          },
          {
            label: 'Previsão',
            to: '/mlf/previsaoFaturamentoBrutoReportView',
          },
        ],
        label: 'Relações de faturamento bruto',
      },
      {
        label: 'Análise DeSTDA',
        to: '/mlf/analiseDeSTDAReportView',
      },
      {
        label: `${CONHECIMENTO_TRASNPORTE}`,
        to: '/mlf/conhecimentoTransporteReportView',
      },
      {
        label: 'Consolidação de Operações de CFOP por Estado',
        to: '/mlf/resumoCFOPInterestadualReportView',
      },
      {
        label: 'Relação de Baixas do Ativo',
        to: '/mlf/baixasAtivoReportView',
      },
      {
        label: 'Resumo Entrada Saída',
        to: '/mlf/resumoEntradaSaidaReportView',
      },
      {
        label: 'Resumo de Movimentação de ST de Entrada/Saída',
        to: '/mlf/resumoMovimentacaoSTEntradaSaida',
      },
      {
        label: `${TERMO_DE_ABERTURA_E_ENCERRAMENTO}`,
        to: '/mlf/termoAberturaEncerramentoMLFReportView',
      },
      {
        label: 'Totais da Máquina Registradora',
        to: '/mlf/totalMaquinaRegistradoraReportView',
      },
      {
        label: 'DEFIS',
        to: '/mlf/DefisReportView',
      },
    ],
    icon: 'pe-7s-print',
    isReportList: true,
    label: 'Relatórios',
  },
  {
    content: [
      {
        label: 'Recolhimento de Impostos',
        to: '/mlf/graficoRecolhimentoImpostoProcessView',
      },
      {
        label: 'Faturamento Bruto',
        to: '/mlf/graficoFaturamentoBrutoProcessView',
      },
      {
        label: 'Previsão de Faturamento Bruto - Base Ano Corrente',
        to: '/mlf/graficoPrevisaoFaturamentoBrutoAnoProcessView',
      },
      {
        label: 'Previsão de Faturamento Bruto - Base Ano Anterior',
        to: '/mlf/graficoPrevisaoFaturamentoBrutoAnoAnteriorProcessView',
      },
      {
        calimaPro: true,
        label: 'Faturamento X Entradas',
        to: '/mlf/graficoFaturamentoComprasProcessView',
      },
    ],
    icon: 'pe-7s-graph1',
    label: 'Gráficos',
  },
];

export default mlfMenu;
