import React, { FC, useEffect, useRef, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { compare, CRITICAL, currentDate, ERROR, eventManager, formatDate, isEmpty, isNotEmpty, showToast } from 'summer';
import { GRUPO_REL_UPDATE_EVENT } from '../../../models/dtos/ger/grupoRelatorio';
import { UsuarioHistAcesso } from '../../../models/dtos/ger/usuarioHistAcesso';
import { EventosPendentesEnvioAutomaticoESocialParam } from '../../../models/dtos/mfp/eventosPendentesEnvioAutomaticoESocialParam';
import { checkUsuarioVisualizouTour, getGruposRelPorModulo } from '../../../services/ger.service';
import {
  ajustarContratosVencidos,
  buscarEventosPeriodicosPendentes,
  envioAutomaticoComErro,
  getUltimaPaginaAcessada,
  verificarPendenciasMFP,
} from '../../../services/mfp.service';
import { Reducers } from '../../../store/ducks';
import { abrirTourReinfPrimeiraVez } from '../../../store/ducks/global.duck';
import boxMenu from '../../pages/box/boxMenu';
import connectMenu from '../../pages/connect/connectMenu';
import gerMenu from '../../pages/ger/gerMenu';
import mcgMenu from '../../pages/mcg/mcgMenu';
import mcpMenu from '../../pages/mcp/mcpMenu';
import mfpMenu from '../../pages/mfp/mfpMenu';
import mhoMenu from '../../pages/mho/mhoMenu';
import mhoMenuNitrus from '../../pages/mho/mhoMenuNitrus';
import micMenu from '../../pages/mic/micMenu';
import mlfMenu from '../../pages/mlf/mlfMenu';
import mprMenu from '../../pages/mpr/mprMenu';
import siteMenu from '../../pages/site/siteMenu';
import EventosPendentesESocialModalView from '../eventosPendentesESocial/EventosPendentesESocialModalView';
import SidebarNav from './SidebarNav';
import SidebarVersionBox from './SidebarVersionBox';

const SidebarMenu: FC = () => {
  const {
    histAcessoReducer: { recents },
    globalReducer: { currentState, globalParameter, accessOldHonorarios },
  } = useSelector<Reducers, Reducers>(state => state);
  const [currentMenu, setCurrentMenu] = useState([]);
  const [eventosPendentesModal, setEventosPendentesModal] = useState(null);

  const isTouchEvent = useRef<boolean>(false);
  const containerRef = useRef<any>();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(
    () => {
      eventManager.on(GRUPO_REL_UPDATE_EVENT, () => updateMenu());
      return () => eventManager.off(GRUPO_REL_UPDATE_EVENT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentState]
  );

  useEffect(
    () => {
      updateMenu();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentState, accessOldHonorarios]
  );

  const notificarAjustarContratos = () => {
    ajustarContratosVencidos(globalParameter.usuario, {
      thenFunction: () => null,
    });
  };

  const onCloseEventosPendentesModal = () => setEventosPendentesModal(null);

  const verificarEventosPendentesESocial = () => {
    buscarEventosPeriodicosPendentes({
      errorFunction: mensagem => showToast(mensagem, ERROR),
      thenFunction: (eventos: EventosPendentesEnvioAutomaticoESocialParam[]) => {
        if (eventos.length > 0) {
          setEventosPendentesModal(<EventosPendentesESocialModalView onClose={onCloseEventosPendentesModal} eventos={eventos} />);
        }
      },
    });
  };

  const verificarErroEnvioAutomatico = () => {
    envioAutomaticoComErro({
      errorFunction: mensagem => showToast(mensagem, ERROR),
      thenFunction: result => {
        if (result) {
          showToast(
            'Foram detectados erros nos eventos enviados automaticamente',
            CRITICAL('eSocial - Erro no envio'),
            () => history.push('/mfp/monitorEventoEsocialProcessView', { envioAutomatico: true }),
            'Clique aqui para visualizar e corrigir'
          );
        }
      },
    });
  };

  const primeiroAcessoDiaFolha = () => {
    getUltimaPaginaAcessada(globalParameter.usuario.id, {
      errorFunction: mensagem => showToast(mensagem, ERROR),
      thenFunction: (ultimaPaginaAcessada: UsuarioHistAcesso) => {
        const semHistoricoTelasRecentes = recents.length === 0 && isEmpty(ultimaPaginaAcessada);
        const naoAcessouSistemaHoje = isNotEmpty(ultimaPaginaAcessada) && compare(ultimaPaginaAcessada.dataAcesso, formatDate(currentDate())) < 0;
        const ultimaTelaAcessadaFolha = recents.find(it => it.modulo === 'MFP');
        const nuncaAcessouQualquerTelaFolha = isEmpty(ultimaTelaAcessadaFolha) && recents.length > 0;
        const naoAcessouFolhaHoje =
          nuncaAcessouQualquerTelaFolha || (ultimaTelaAcessadaFolha && compare(ultimaTelaAcessadaFolha.dataAcesso, formatDate(currentDate())) < 0);
        if (semHistoricoTelasRecentes || naoAcessouSistemaHoje || naoAcessouFolhaHoje) {
          notificarAjustarContratos();
          verificarEventosPendentesESocial();
          verificarErroEnvioAutomatico();
        }
      },
    });
  };

  const updateMenu = () => {
    let menu;

    switch (currentState) {
      case 'GER':
        menu = gerMenu;
        break;
      case 'MCG':
        menu = mcgMenu;
        break;
      case 'MCP':
        menu = mcpMenu;
        break;
      case 'MFP':
        menu = mfpMenu;
        if (globalParameter.acesso.folhaHabilitado) {
          primeiroAcessoDiaFolha();

          // Se o módulo folha estiver habilitado valida as pendencias
          verificarPendenciasMFP(globalParameter.usuario, {
            errorFunction: mensagem => showToast(mensagem, ERROR),
            thenFunction: () => null,
          });
        }
        break;
      case 'MHO':
        const nitrusOrMho = globalParameter.acesso.temAcessoAoNitrus && accessOldHonorarios;
        menu = nitrusOrMho ? mhoMenuNitrus : mhoMenu;
        break;
      case 'MLF':
        menu = mlfMenu;
        if (globalParameter.empresaMLF) {
          verificarTourReinf();
        }
        break;
      case 'MPR':
        menu = mprMenu;
        break;
      case 'BOX':
        menu = boxMenu;
        break;
      case 'CONNECT':
        menu = connectMenu;
        break;
      case 'SITE':
        menu = siteMenu;
        break;
      case 'MIC':
        menu = micMenu;
        break;
      default:
    }

    if (menu) {
      loadReportGroups(menu).then((res: any[]) => setCurrentMenu(res));
    }
  };

  const loadReportGroups = menu =>
    new Promise(resolve => {
      getGruposRelPorModulo(currentState, {
        errorFunction: mensagem => {
          showToast(mensagem, ERROR);
          resolve(menu);
        },
        thenFunction: res => {
          if (res && res.length) {
            resolve(
              menu.map(it => {
                const result = { ...it };
                if (result.isReportList) {
                  result.content = [
                    {
                      content: res.map(item => ({ label: item.nome, to: `/${currentState.toLowerCase()}/grupoRel/${item.id}` })),
                      label: 'Grupos',
                    },
                    ...it.content,
                  ];
                }
                return result;
              })
            );
          } else {
            resolve(menu);
          }
        },
      });
    });

  const lockBody = () => {
    if (isTouchEvent.current) {
      isTouchEvent.current = false;
    } else {
      const body = document.querySelector('body');
      const navbar = document.querySelector('.app-header') as HTMLDivElement;

      const withScroll = body.offsetWidth;
      body.style.overflow = 'hidden';
      const withoutScroll = body.offsetWidth;

      const diff = withoutScroll - withScroll;
      if (diff > 0) {
        body.style.marginRight = `${diff}px`;
        navbar.style.transition = 'none';
        navbar.style.right = `${diff}px`;
      }
    }
  };

  const unlockBody = () => {
    const body = document.querySelector('body');
    const navbar = document.querySelector('.app-header') as HTMLDivElement;

    body.style.overflow = 'auto';
    body.style.marginRight = '0px';
    navbar.style.right = '0px';

    setTimeout(() => {
      navbar.style.transition = 'all .5s cubic-bezier(.685,.0473,.346,1)';
    }, 200);
  };

  const handleScrollContainerRef = (ref: HTMLDivElement) => {
    ref.addEventListener('mouseenter', lockBody, false);
    ref.addEventListener('mouseleave', unlockBody, false);
    ref.addEventListener(
      'touchstart',
      () => {
        isTouchEvent.current = true;
      },
      false
    );
  };

  const verificarTourReinf = () => {
    checkUsuarioVisualizouTour(globalParameter.usuario.id, 'MUDANCAS_REINF', {
      errorFunction: message => showToast(message, ERROR),
      thenFunction: res => {
        if (!res) {
          setTimeout(() => dispatch(abrirTourReinfPrimeiraVez('arquivosdigitais')), 500);
        }
      },
    });
  };

  return (
    <PerfectScrollbar ref={containerRef} containerRef={handleScrollContainerRef}>
      {eventosPendentesModal}
      <div className={`sidebar-navigation sidebar-${currentState.toLowerCase()}`}>
        <SidebarNav items={currentMenu} scrollRef={containerRef} />
      </div>
      <SidebarVersionBox />
    </PerfectScrollbar>
  );
};

export default SidebarMenu;
